<template>
  <div class="export">
    <div class="date-select">
      <b-field label="เริ่มต้น">
        <b-datepicker
          placeholder="เริ่มต้น"
          v-model="filter.start"
          style="max-width: 400px"
          icon="calendar-day"
        ></b-datepicker>
      </b-field>
      <b-field label="สิ้นสุด"
        ><b-datepicker
          placeholder="สิ้นสุด"
          v-model="filter.end"
          style="max-width: 400px"
          icon="calendar-day"
        ></b-datepicker
      ></b-field>
    </div>
    <div class="action">
      <b-button
        @click="onExportCSV"
        :disabled="
          filter.start == null ||
          filter.end == null ||
          filter.start > filter.end
        "
        :loading="loading"
        type="is-primary"
        >{{ $t("Export") }}</b-button
      >
      <b-button @click="onClose" type="is-light">{{ $t("Close") }}</b-button>
      <!-- <b-button @click="onExportCSV" disabled type="is-success is-light">{{
            $t("Export")
          }}</b-button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ExportBox",
  props: ["loading"],
  data() {
    return {
      raw: null,
      filter: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    onExportCSV() {
      console.log("Export CSV", this.filter);
      this.$emit("on-export", this.filter);
    },
    onClose() {
      this.$emit("on-close");
    },
  },
};
</script>

<style scoped>
.date-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.export {
  padding: 15px;
  margin: 10px 0;
  border-radius: 15px;
  height: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
}
.action {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 10px;
}
@media screen and (max-width: 400px) {
  .filtering {
    flex-direction: column;
  }
}
</style>